.SearchInput {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    background-color: var(--white);
    border: 3px solid var(--yellowBackground);
    border-radius: 5px;
}

.SearchInput > div {
    padding: 10px 15px;
    //background-color: var(--primaryValeco);
    color: var(--primaryValeco);
    width: fit-content;
}

.SearchInput > input {
    all: unset;
    padding: 0 10px;
    flex-grow: 1;
    font-size: 14px;
    color: var(--black);
}

.SearchInput > input::placeholder {
    color: var(--primaryValeco);
    font-size: 12px;
    opacity: 0.8;
}

.FilteredList {
    position: absolute;
    z-index: 1000;
    max-height: 40vh;
    border: 1px solid var(--primaryValeco);
    background-color: var(--white);
    overflow-y: scroll;
}

.FilteredObject {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 0;
}

.Card {
    padding: 2px 10px;
    cursor: pointer;
}

.Card:hover {
    background-color: var(--secondaryValeco);
    color: white;
    font-weight: bold;
}