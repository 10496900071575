.Loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    flex-grow: 1;
}

.title {
    text-align: center;
    font-family: 'DinPro', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 30px;
    color: white;
    transform: translateY(-200%);
    animation: 2s linear infinite loop;
    padding: 15px;
    border-radius: 20px;
    background-color: rgba(10, 10, 10, 0.8);
}

.subtitle {
    font-family: 'DinPro', sans-serif;
    font-size: 20px;
}

.waitGif {
    max-height: 80vh;
}


@keyframes loop {
    0% {color: white;}
    50% {color: rgb(236, 236, 236);}
    100% {color: white;}

}