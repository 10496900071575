.IconFrame {
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProjectFolder {
    border-bottom: 2px solid var(--yellowBackground);

    .ProjectCard {
        display: flex;
        align-items: stretch;
        min-height: 50px;
        border-radius: 5px;
        border: 2px solid transparent;

        &:hover {
            background-color: var(--yellowBackground);
            border: 2px solid var(--yellowBackground);

        }

        &.IsOpen {
            background-color: var(--secondaryValeco);
            border: 2px solid var(--secondaryValeco);
            color: var(--white);

            .ProjectSymbol, .ProjectName {
                color: var(--white);
            }
        }
    }

    .ProjectInfo {
        display: flex;
        flex-grow: 1;
        cursor: pointer;

        .ProjectSymbol {
            color: var(--primaryValeco);
            cursor: pointer;
        }

        .ProjectName {
            margin: 5px 0 5px 5px;
            flex-grow: 1;
            font-size: 14px;
            font-weight: bold;
            width: min-content;
            align-self: center;
            color: var(--primaryValeco);
        }
    }
}

.ProjectFolderButton {
    cursor: pointer !important;

    > * {
        cursor: pointer !important;
    }

    > div {
        font-size: 14px;
        color: var(--black);
    }

    &.ProjectFolderIsOpen {
        background-color: var(--primaryValeco);
        color: var(--white);
         > * {
            background-color: var(--primaryValeco);
            color: var(--white);
        }

        &:hover {
            background-color: var(--blueRp);

            > * {
                background-color: var(--blueRp);
            }
        }
    }

    &:hover {
        background-color: #FFFFFF88;
        color: var(--black);

        > .material-icons {
            transform: scale(1.2);
        }
    }
}

.FavoriteIcon {
    color: var(--lightGrey) !important;
    font-size: 1rem !important;
    text-shadow: 0 0 1px white, 0 0 2px white, 0 0 3px white;

    &.ProjectIsFavorite {
        color: var(--secondaryValeco) !important;
    }
}

.ProjectIsUpdating {
    color: var(--lightGrey) !important;
}

.MeetingsGroup{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 30px;
    padding: 5px 0;
}

.CreateProject {
    display: flex;
    align-items: stretch;
    min-height: 34px;
    cursor: pointer;
    background-color: #F4F4F4;
    box-shadow: 0 0 2px var(--darkGrey);

    .ProjectName {
        margin: 5px 0 5px 5px;
        flex-grow: 1;
        font-size: 14px;
        font-weight: bold;
        width: min-content;
        align-self: center;
    }

    .Icon {
        color: var(--primaryValeco);
        cursor: pointer;
    }

    &:hover {
        background-color: var(--blueRp);
    }
}

.ImportProject {
    display: flex;
    align-items: center;
    color: var(--grey);
    font-size: 0.8rem;
    border: 2px solid transparent;
    cursor: pointer;
    margin: 10px 0;

    .ButtonName {
        transform: translateY(1.5px);
    }

    &:hover {
        background-color: var(--inputBorder);
        color: var(--primaryValeco);
        border-radius: 5px;
    }
}