

.ConfirmModalContent {
    padding: 20px;
    flex-grow: 1;

    > h1, h2, h3, h4, h5, h6 {
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--primaryValeco);
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
    }
}

.ConfirmModalButtonBox {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin: 0 10px;
    padding: 10px;
    border-top: 1px solid var(--lightGrey);
}

.CancelButton {
    background-color: var(--blueRp);
    padding: 8px 20px 6px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    color: var(--primaryValeco);
    box-shadow: 0 0 2px var(--darkGrey);

    &:hover {
        box-shadow: 0 0 4px var(--darkGrey);
        &:active {
            box-shadow: inset 0 0 2px var(--darkGrey);
        }
    }
}

.AcceptButton {
    background-color: var(--secondaryValeco);
    padding: 8px 20px 6px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    color: var(--white);
    font-weight: bold;
    text-shadow: 0 0 1px var(--black);
    box-shadow: 0 0 2px var(--black);

    &:hover {
        box-shadow: 0 0 4px var(--black);
        &:active {
            box-shadow: inset 0 0 2px var(--black);
        }
    }
}

