.Dashboard {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.DashboardNav {
    display: flex;
    overflow-y: auto;
    background-color: rgb(236, 236, 236);
    box-shadow: 0 0 5px black;
    z-index: 2;
    min-width: fit-content;
}

.DashboardReport {
    display: flex;
    flex-grow: 1;
    overflow-x: auto;
    overflow-y: auto;
}

.DashboardBody {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex: 1 1 auto;
    overflow: hidden;
}