.ReportContainer {
    flex-grow: 1;
    max-width: 1440px;
    margin: auto;
    padding: 40px;
    background-color: var(--white);

    .Workflow {
        display: flex;
        padding: 30px;
        gap: 20px;
        font-family: 'DinPro', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: normal;
        color: var(--primaryValeco);

        > div {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .Value {
            font-weight: bold;
        }
    }
}

.EditMode {
    outline-offset: -10px;
    outline: var(--secondaryValeco) dashed 5px;
}

.ContainerList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    background-color: var(--yellowBackground);
    border-radius: 5px;
}

.FullViewButton {
    color: var(--white);
}