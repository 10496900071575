.IconFrame {
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;

    span, div {
        font-size: 20px;
    }
}

.SmallIconFrame {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span, div {
        font-size: 18px;
        cursor: pointer;
    }

    &:hover {
        transform: scale(1.4);
    }
}

.Report {
    display: flex;
    align-items: stretch;
    min-height: 34px;
    padding: 0 10px;
    margin: 3px 0;
    border-left: 4px solid var(--lightGrey);
    cursor: pointer;
    color: var(--black);

    &.ReportShowMore {
        border-image: linear-gradient(
                        #C4C4C4FF 35%,
                        var(--white) 35%,
                        var(--white) 45%,
                        #C4C4C47F 45%,
                        #C4C4C47F 75%,
                        var(--white) 75%,
                        var(--white) 85%,
                        #C4C4C440 85%,
                        #C4C4C440 100%) 0 0 0 1;
    }



    .ReportClickable {
        display: flex;
        flex-grow: 1;

        .IconFrame {
            color: var(--lightGrey);
        }
        .ReportName {
            display: flex;
            flex-grow: 1;
            font-size: 14px;
            align-self: center;
            transform: translateY(2px);
        }
    }

    &.Loaded {
        color: var(--secondaryValeco);
        font-weight: 900;

        .IconFrame {
            color: var(--secondaryValeco);
        }

        &:hover {
            background-color: var(--yellowBackground);
        }
    }

    &:hover {
        color: var(--secondaryValeco);
    }
}