

.TaskFormHeader {
    background-color: var(--secondaryValeco);
    color: var(--white);
    font-weight: bold;
    padding: 20px 20px;

    h2 {
        margin: 0;
    }
}

.TaskFormBody {
    padding: 10px 20px;
    flex-grow: 1;
}

.TaskFormButton {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 10px 20px;

    .CancelBtn {
        background-color: var(--blueRp);
        padding: 3px 10px;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;

        &:hover {
            box-shadow: 0 0 2px var(--grey);
        }
    }

    .ContinueBtn {
        background-color: var(--secondaryValeco);
        padding: 3px 10px;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;

        &:hover {
            box-shadow: 0 0 2px var(--grey);
        }
    }
}

.EnterpriseCardBox {
    position: absolute;
    transform: translateX(54px);
    border: 1px solid var(--primaryValeco);
    max-height: 30vh;
    overflow-y: scroll;
    flex-grow: 1;
}

.EnterpriseCard {
    padding: 2px 10px;
    cursor: pointer;

    &:hover {
        background-color: var(--secondaryValeco);
        color: white;
        font-weight: bold;
    }
}

.SearchCard {
    padding: 5px 10px;
    border-bottom: 1px solid var(--lightGrey);
    background-color: var(--white);
    cursor: pointer;
    &:hover {
        font-weight: bold;
        color: var(--secondaryValeco);
    }
}

.TaskForm {
    display: flex;
    flex-direction: column;

    label {
        padding: 10px 0 0;
        font-weight: bold;
        color: var(--primaryValeco);
    }

    .Head {
        display: flex;
        gap: 10px;
        justify-content: space-between;

        > div {
            display: flex;
            flex-direction: column;
            flex-basis: 30%;
        }
    }



    .DueDateMode{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        label {
            padding-top: 0;
            color: var(--grey);
            font-size: 0.8rem;
            font-weight: lighter;
        }

        select {
            all: unset;
            color: var(--grey);
            font-size: 0.8rem;
            font-weight: lighter;
            cursor: pointer;
            &:hover {
                color: var(--secondaryValeco);
            }

            option {
                color: var(--primaryValeco);
            }
        }
    }
}