.IconFrame {
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MeetingCard {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  min-height: 34px;
  cursor: pointer;
  background-color: var(--yellowBackground);
  border-radius: 5px;
  &:hover .MeetingName {
    color: var(--secondaryValeco);
  }
}

.BodyCard {
  display: flex;
  flex-grow: 1;
}

.MeetingName {
  flex-grow: 1;
  font-size: 14px;
  width: min-content;
  align-self: center;
  margin: 5px 0 5px 10px;
  color: var(--primaryValeco);

  &.isOpen {
    color: var(--secondaryValeco);
    font-weight: bold;
  }
}

.ReportGroup {
  margin-left: 30px;
  background-color: var(--white);
}

.CreateMeeting {
  display: flex;
  align-items: center;
  color: var(--grey);
  font-size: 0.8rem;
  border: 2px solid transparent;
  cursor: pointer;

  .ButtonName {
    transform: translateY(1.5px);
  }

  &:hover {
    background-color: var(--inputBorder);
    color: var(--primaryValeco);
    border-radius: 5px;
  }
}

.MeetingForm {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;

  input {
    width: calc(100% - 24px);
    background-color: var(--white);
  }

 .MeetingType {
   padding-left: 10px;
   display: flex;
   align-items: center;
   gap: 20px;
   color: var(--primaryValeco);

   select {
     flex-grow: 1;
     background-color: var(--white);
     font-size: 16px;
     padding: 5px 10px;
   }
 }

}

.GrantButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  color: var(--primaryValeco);
  cursor: pointer;

  span {
    font-size: 1rem;
  }

  &:hover {
    background-color: var(--secondaryValeco);
    color: var(--white);
  }
}