.RightManager {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  .HeaderStep {
    background-color: var(--darkGrey);
    margin: auto;
    padding: 0.1rem 0.2rem;
    font-size: 1.5rem;
    color: var(--white);
  }

  .WorkFlow {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 20px auto;
    min-width: 400px;
  }

  .StepBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--darkGrey);

    .Step {
      background-color: var(--darkGrey);
      padding: 0.1rem 0.2rem;
      font-size: 1rem;
      color: var(--white);
    }

    &.Disable {
      color: var(--lightGrey);

      .Step {
        background-color: var(--lightGrey);
      }
    }
  }

  .StepButtonBox{
    display: flex;
    flex-grow: 1;
    gap: 20px;
    justify-content: space-between;
  }

  .StepButton {
    flex-grow: 1;
    background-color: var(--secondaryValeco);
    padding: 20px;
    color: var(--white);
    font-weight: bold;
    text-align: center;
    box-shadow: 0 0 2px var(--darkGrey);
    border-radius: 5px;
    user-select: none;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 4px var(--black);
      transform: scale(1.05);
    }

    &.Disable {
      background-color: var(--yellowRp);
      color: var(--grey);
      box-shadow: none;
      cursor: not-allowed;

      &:hover {
        box-shadow: none;
        transform: scale(1);
      }
    }

    &.Done {
      background-color: var(--primaryValeco);
      cursor: default;

      &:hover {
        box-shadow: none;
        transform: scale(1);
      }
    }

    &.Cancel {
      background-color: #AC0404;
    }

  }

  .Header {
    display: flex;
    padding: 20px 50px;
    background-color: var(--secondaryValeco);

    h2 {
      flex-grow: 1;
      margin: 0;
      color: var(--white);
      font-size: 44px;
      font-weight: 900;
    }

    .SearchBar {
      width: 30vw;
      min-width: 300px;

      .UserSelect {
        position: absolute;
        z-index: 1000;
        width: 30vw;
        min-width: 300px;
        max-height: 40vh;
        border: 1px solid var(--primaryValeco);
        background-color: var(--white);
        overflow-y: scroll;

        .FilteredUser {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }

  .SubHeader {
    position: relative;
    width: fit-content;
    transform: translate(50px, -30%);
    padding: 10px 20px;
    min-width: 400px;

    background-color: var(--primaryValeco);
    color: var(--white);

    h3 {
      margin: 0;
      font-weight: 600;
    }

    h5 {
      margin: 0;
    }
  }

  .Body {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px ;
    flex-grow: 1;

    .Title {
      color: var(--black);
      font-size: 52px;
      margin: 0;
    }

    .Description {
      margin: 0 {
        left: 40px;
        bottom: 10px;
      };
      color: var(--black);

      .Meeting {
        font-style: italic;
        font-weight: 700;
      }
    }

    .ListBox {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--lightGrey);
      padding: 10px;
      flex-grow: 1;
      gap: 10px;
    }
  }
}