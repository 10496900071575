
.UserBox {
  display: flex;
  gap: 5px;
  padding-left: 20px;
  background-color: #F4F4F4;
  overflow: hidden;

  .Firstname {
    align-self: center;
    font-size: 16px;
  }

  .Lastname {
    flex-grow: 1;
    align-self: center;
    font-weight: bold;
    font-size: 16px;
  }

  .Company {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    text-align: right;

    .Main {
      font-weight: bold;
    }

    .Secondary {
      font-size: 10px;
      color: var(--grey);
    }
  }
}

.ActionBox {
  width: 24px;
  overflow: visible;
  .ActionButton {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    width: max-content;
    height: 100%;
    padding: 0 5px;
    background-color: #AC0404;
    color: var(--white);
    cursor: pointer;
    transition: 1s ease-in-out;
    user-select: none;

    > div {
      cursor: pointer;
      font-size: 14px;
    }

    &:hover {
      transform: translateX(calc(-100% + 24px));
      transition: 1s ease-out;
    }
  }
}