.Toolbar {
    display: flex;
    align-items: stretch;
    padding: 10px;
    background-color: var(--yellowRp);
    gap: 10px;
    border: 1px solid var(--secondaryValeco);
    border-radius: 4px;
    margin-bottom: 2px;
}

.ToolbarButton {
    display: flex;
    color: #222222;
    line-height: 0;
    user-select: none;
    border-radius: 5px;
}

.ToolbarButton:hover {
    outline: 1px solid #888888;
}

/* ------------- StyleButton ------------- */
.StyleButton {
    padding: 3px;
    border-radius: 5px;
}

.StyleButton:hover:not(.active) {
    background-color: #FAFAFA;
    cursor: pointer;
}

.StyleButton:active {
    box-shadow: inset 0 0 2px black;
}

/* ----------- TextColorButton ----------- */

.TextColorButtonIcon {
    padding: 3px;
    border-radius: 5px 0 0 5px;
}

.TextColorButtonDropDown {
    padding: 3px 0;
    border-radius: 0 5px 5px 0;
}

.TextColorButtonIcon:hover:not(.active), .TextColorButtonDropDown:hover {
    background-color: #FAFAFA;
    cursor: pointer;
}

.TextColorButtonIcon:active {
    box-shadow: inset 0 0 2px black;
}

/* ------------- ColorOption ------------- */
.ColorSelector {
    position: relative;
}

/* ------------- ColorOption ------------- */
.ColorOption {
    display: inline-block;
    height: 24px;
    width: 24px;
    margin: 3px;
    border-radius: 5px;
}

.ColorOption:hover {
    outline: 3px solid #F7A600;
}

/* ------------- StyleButton ------------- */
.StyleButton.active, .TextColorButtonIcon.active {
    color: #222222;
    background-color: #FFFFFF;
    box-shadow: inset 0 0 2px var(--lightGrey);
}

/* ------------ ToolbarSpacer ------------ */
.ToolbarSpacer {
    width: 1px;
    margin: 0 10px;
    background-color: #222222;

}