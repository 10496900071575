.RightManager {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  .Header {
    display: flex;
    padding: 20px 50px;
    background-color: var(--secondaryValeco);

    h2 {
      flex-grow: 1;
      margin: 0;
      color: var(--white);
      font-size: 44px;
      font-weight: 900;
    }

    .SearchBar {
      width: 30vw;
      min-width: 300px;

      .UserSelect {
        position: absolute;
        z-index: 1000;
        width: 30vw;
        min-width: 300px;
        max-height: 40vh;
        border: 1px solid var(--primaryValeco);
        background-color: var(--white);
        overflow-y: scroll;

        .FilteredUser {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }

  .SubHeader {
    position: relative;
    width: fit-content;
    transform: translate(50px, -30%);
    padding: 10px 20px;
    min-width: 400px;

    background-color: var(--primaryValeco);
    color: var(--white);

    h3 {
      margin: 0;
      font-weight: 600;
    }

    h5 {
      margin: 0;
    }
  }

  .Body {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px ;
    flex-grow: 1;

    .Title {
      color: var(--black);
      font-size: 52px;
      margin: 0;
    }

    .Description {
      margin: 0 {
        left: 40px;
        bottom: 10px;
      };
      color: var(--black);

      .Meeting {
        font-style: italic;
        font-weight: 700;
      }
    }

    .ListBox {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--lightGrey);
      padding: 10px;
      flex-grow: 1;
      gap: 10px;
    }
  }
}