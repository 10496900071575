
.NotificationList {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2px;
    min-width: 300px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100000;
}

.NotificationHead {
    display: flex;
    align-items: center;
    gap: 10px;
}

.NotificationTitle {
    font-weight: bold;
    flex-grow: 1;
}

.closeButton {
    border: 2px solid transparent;
    border-radius: 5px;

    &:hover {
        background-color: #FFFA;
        border: 2px solid #FFF;
    }
}

.NotificationFrame {
    display: flex;
    flex-direction: column;
    padding: 5px 5px 5px 10px;
}

.error {
    background-color: var(--dangerBackground);
    color: var(--danger);
    border: 2px solid var(--danger);
}

.warning {
    background-color: var(--warningBackground);
    color: var(--warning);
    border: 2px solid var(--warning);
}

.information {
    background-color: var(--informationBackground);
    color: var(--information);
    border: 2px solid var(--information);
}

.success {
    background-color: var(--successBackground);
    color: var(--success);
    border: 2px solid var(--success);
}

.NotificationMessage {
    font-size: 0.8rem;
}

.FatalErrorFrame {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dangerBackground);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    > div {
        max-width: 1040px;
        display: flex;
        flex-direction: column;
    }
}

.FatalErrorTitle {
    padding: 10px 0;
    font-size: 3rem;
    font-weight: bold;
    color: var(--danger);
}

.FatalErrorMessage {
    padding: 10px 20px;
    background-color: white;

}

.FatalErrorCreateTicketButton {
    all: unset;
    margin: 20px auto;
    align-self: center;
    background-color: var(--danger);
    padding: 10px 20px;
    border-radius: 5px;
    color: var(--white);
    font-weight: bold;
    box-shadow: 0 0 2px var(--black);
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 5px var(--black);
    }

    &:active {
        box-shadow: inset 0 0 2px var(--black);
    }
}

.FatalErrorDetailsTitle {
    color: var(--danger);
    border: 2px solid transparent;
    border-radius: 5px;
    width: fit-content;
    padding: 2px 5px;
    cursor: pointer;
    &:hover {
        border: 2px solid var(--danger);
    }
}

.FatalErrorDetails {
    background-color: var(--white);
    padding: 5px 10px;
    white-space: pre-wrap;
}

.UnavailableApiFrame {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--informationBackground);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    > div {
        max-width: 1040px;
        display: flex;
        flex-direction: column;
    }
}

.UnavailableApiTitle {
    padding: 10px 0;
    font-size: 3rem;
    font-weight: bold;
    color: var(--information);
}

.UnavailableApiMessage {
    padding: 10px 20px;
    background-color: white;
}

.UnavailableApiLogoutButton {
    all: unset;
    margin: 20px auto;
    align-self: center;
    background-color: var(--information);
    padding: 10px 20px;
    border-radius: 5px;
    color: var(--white);
    font-weight: bold;
    box-shadow: 0 0 2px var(--black);
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 5px var(--black);
    }

    &:active {
        box-shadow: inset 0 0 2px var(--black);
    }
}