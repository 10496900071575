.InputInvalid {
    border-color: var(--danger) !important;
    color: var(--danger) !important;
}

.InputInvalidMessage {
    padding: 3px 0;
    text-align: right;
    color: var(--danger);
    font-size: 0.8rem;
}

.InputDate, .Input {
    all: unset;
    color: var(--primaryValeco);
    border: 2px solid var(--inputBorder);
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    width: fit-content;

    &::-webkit-calendar-picker-indicator {
        z-index: 1;
        width: 16px;
        height: 16px;
        filter: opacity(0);
        cursor: pointer;
    }

    &::after {
        font-family: 'Material Icons Rounded', sans-serif;
        content: 'calendar_month';
        font-size: 1rem;
        width: 0;
        transform: translateX(-1rem);
    }

    &:focus {
        background-color: white;
        box-shadow: inset 0 0 5px var(--grey);

    }
}