.checkbox {
    /*noinspection CssNoGenericFontName*/
    font-family: 'Material Icons Rounded';
    display: flex;
    justify-content: center;
    align-items: center;
    color: #213A8F;
    background: white;
    border: 1px solid #213A8F;
    font-size: 12px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.25);
    margin: auto;
}

.checkbox.checked,
.checkbox.checked > span::before {
    font-size: 20px;
    font-weight: lighter;
    color: white;
    background: #213A8F;
    content: 'close';
}