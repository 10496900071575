.Container {
    margin-bottom: 30px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--yellowBackground);
    border-left: 4px solid var(--yellowBackground);
    border-right: 4px solid var(--yellowBackground);
}

.ContainerHeader {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
}

.Resume {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Indicators {
    display: flex;
    gap: 20px;
}

.ResumeIndicator {
    color: var(--primaryValeco);
    display: flex;
    align-items: center;
    gap: 5px;
}

.ResumeValue {
    font-size: 3rem;
    font-weight: 100;
    transform: scaleX(0.85);
}

.ResumeTag {
    font-weight: lighter;
    max-width: 250px;
}

.ContainerHeader h5 {
    font-size: large;
    text-align: left;
    font-weight: bold;
    color: var(--primaryValeco);
    margin: 0;
}

.showMoreButton {
    width: fit-content;
    padding: 3px 15px 3px 5px;
    display: flex;
    align-items: center;
    gap: 3px;
    color: var(--primaryValeco);
    font-size: 0.8rem;
    font-weight: bold;
    border: 2px solid transparent;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        border: 2px solid var(--inputBorder);

    }
}

.RoleBox {
    color: var(--primaryValeco);
    display: flex;
    align-items: center;
    gap: 10px;
}

.RoleName {
    font-variant: small-caps;
    font-size: 2rem;
}

.AddButton {
    color: var(--grey);
    border: 2px solid transparent;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        border: 2px solid var(--inputBorder);
    }
}

.ParticipantCard {
    display: grid;
    grid-template-columns: 30px 60px 240px 1fr 150px;
    align-items: center;
    gap: 10px;
    border: 2px solid transparent;
    border-radius: 5px;
    margin-top: 9px;
    &:hover {
        border: 2px solid var(--inputBorder);
        background-color: var(--inputBorder);
    }
}

.OrderActionBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--inputBorder);

    input {
        font-size: 0.8rem;
        width: 24px;
        padding: 5px 0;
        text-align: center;
        color: var(--grey);

        &:hover {
            border-color: var(--grey);
            color: var(--darkGrey);
        }
    }

    div:hover {
        color: var(--grey);
    }
}

.UserPictureFrame {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--white);
    border-radius: 50%;
    border: 3px solid var(--white);

    span {
        transform: translateY(3px);
    }
}

.UserDetailsBox {
    display: flex;
    flex-direction: column;

}

.UserName {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 0 5px;
    color: var(--black);
    font-size: 1.2rem;
    .Lastname {
        font-weight: bold;
    }
}

.EnterpriseName {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--grey);
    font-size: 0.8rem;
}

.EnterpriseOption {
    display: flex;
    justify-content: flex-end;
    align-items: center;


    select {
        text-align: end;
        background-color: transparent;
        border: 2px solid var(--inputBorder);
        border-radius: 5px;
        display: inline-block;
        font-family: 'DinPro', sans-serif;
        padding: 5px 20px 3px 5px;
        margin: 0;
        color: var(--grey);
        font-size: 0.8rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image:
                linear-gradient(45deg, transparent 50%, gray 50%),
                linear-gradient(135deg, gray 50%, transparent 50%);
        background-position:
                calc(100% - 10px) calc(0.4em + 4px),
                calc(100% - 5px) calc(0.4em + 4px);
        background-size:
                5px 5px,
                5px 5px;
        background-repeat: no-repeat;
        &:focus {
            outline: 0;
        }
    }
}

.UserDetail {
    display: flex;
    align-items: center;
    gap: 5px;

    .Icon {
        font-size: 0.9rem;
        color: var(--primaryValeco);
    }

    .Value {
        color: var(--darkGrey);
    }
}

.AttendanceBox {
    display: flex;
    gap: 5px;
}

.AttendanceButton {
    font-size: 0.8rem;
    border-width: 2px;
    border-radius: 20px;
    padding: 1px 8px;

    &.isEditing {
        cursor: pointer;
    }
}

.ActionBar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    color: var(--lightGrey);

    .ButtonIcon {
        font-size: 2rem;
        cursor: pointer;

        &:hover {
            border-color: var(--grey);
            color: var(--darkGrey);
        }
    }
}

.AddBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    cursor: pointer;
    padding: 3px 10px;
    width: 100%;
    max-height: 10px;
    overflow: hidden;
    border-bottom: 1px solid var(--lightGrey);
    user-select: none;

    &:hover {
        max-height: 50px;
        color: var(--primaryValeco);
        background-color: var(--inputBorder);
        transition: max-height 500ms ease-out;
        border-bottom: 1px solid transparent;
        border-radius: 5px;
    }
}

.DragDropZone {
    font-size: 0.6rem;
    color: var(--grey);
    text-align: center;
    border: 1px dashed var(--grey);
    border-radius: 5px;
    padding-top: 2px;
}

.SearchCard {
    padding: 2px 10px;
    cursor: pointer;
    color: var(--primaryValeco);

    &:hover {
        background-color: var(--secondaryValeco);
        color: white;
        font-weight: bold;
    }
}

.UserForm {
    color: var(--primaryValeco);

    label {
        font-weight: bold;
    }
}