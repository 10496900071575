.ObfuscateBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #222222AA;
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.ModalPosition {
    margin: auto;
    background-color: white;
    min-width: 40%;
    max-width: 80%;
}

.ModalStyle {
    min-width: 30%;
    background-color: var(--yellowBackground);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}