body {
    overflow: clip;
}

.HomePage {
    /*noinspection CssUnknownTarget*/
    background-image: url("assets/img/bg01.jpg"), linear-gradient(var(--grey), var(--grey));
    background-size: cover;
    background-position: center;
    height: 100vh;
}

.LoginBtn {
    all:unset;
    font-family: 'DinPro', sans-serif;
    font-weight: bold;
    font-size: 6vh;
    width: fit-content;
    margin: 0 auto 5rem;
    padding: 1rem;
    transform: skew(-20deg) translateX(-105%);
    background-color: var(--secondaryValeco);
    color: white;
    z-index: 1;
    box-shadow: 0 0 5px #222222;
    transition: 200ms;
    text-shadow: 0 0 1px #222222;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}

.LoginBtn:hover {
    box-shadow: 0 0 0.5vw #222222;
    transform:  skew(-20deg) translateX(-105%) scale(1.02);
    transition: 500ms;
}

.LoginBtn:active {
    box-shadow: inset 0 0 0.5vw #222222;
    transform:  skew(-20deg) translateX(-105%) scale(1.02);
}

.LoginBtn > span {
    font-size: 6vh;
}

.BlueSide {
    position: absolute;
    left: 50vw;
    top: 0;
    background-color: #213A8FDD;
    box-shadow: 0 0 5px #222222;
    width: 100vw;
    height: 100vh;
    margin: 0;
    transform: skewX(20deg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ValecoBox {
    transform: skewX(-20deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: fit-content;
    height: 40vh;
}

.FirstLine {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.FirstLine > h2 {
    position: absolute;
    transform: translateX(-105%);
    font-family: 'ExpletusSans', 'ExpletusSansStatic', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 5vh;
    color: white;
    margin: 0;
    text-shadow: 0 0 4px #222222;
}
.ValecoSvg {
    height: fit-content;
    width: fit-content;
    max-height: 18vh;
    max-width: 100%;
}

.ValecoBox > h2 {
    font-family: 'DinPro', sans-serif;
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
    color: white;
}

.LoginFailed {
    z-index: 1;
    position: absolute;
    background-color: #ac0c17;
    padding: 20px 50px;
    font-size: large;
    font-weight: bold;
    color: white;
    box-shadow: 0 0 5px black;
    cursor: pointer;
}


.CheckServices {
    background-color: var(--yellowBackground);
    width: fit-content;
    margin: 0 auto 5rem;
    padding: 1rem;
    transform: skew(-20deg) translateX(-105%);
    display: flex;
    align-items: center;
    gap: 20px;
}

@keyframes load {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

.CheckServices.Fetching {
    color: var(--primaryValeco);
}

.CheckServices.Fetching .material-icons {
    animation: load 4s linear infinite;
}

.CheckServices.Unavailable {
    color: var(--danger);
}

@media (max-width: 1000px) {
    .BlueSide {
        left: 0;
        transform: skewX(0deg);
    }
    .ValecoBox {
        transform: skewX(0deg);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin: 0 auto;
        padding: 0 5rem;
    }
    .FirstLine {
        flex-direction: column;
        align-items: flex-start;
    }
    .FirstLine > h2 {
        position: relative;
        transform: translateX(0);
    }
    .LoginBtn {
        transform:  skew(0) translateX(0);
    }
    .LoginBtn:hover, .LoginBtn:active {
        transform:  skew(0) translateX(0) scale(1.02);
    }
    .CheckServices {
        transform: skew(0) translateX(0);
    }
}