.footerBoite {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 40px;

    padding: 0 50px;

    background-color: var(--darkGrey);
    color: var(--lightGrey);
    font-size: 14px;
}

.footerNav {
    display: flex;
    flex-direction: column;
    line-height: 1.1;
}
.footerNav > a {
    cursor: pointer;
    text-decoration: none;
}
.footerNav > a:link {
    color: var(--lightGrey);
}
.footerNav > a:visited {
    color: var(--lightGrey);
}
.footerNav > a:hover {
    color: var(--grey);
}
.footerNav > a:active {
    color: var(--white);
}

.footerSync {
    color: var(--grey);
}