body {
  margin: 0;
  font-family: 'DinPro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --primaryValeco:  #213A8F;
  --secondaryValeco: #F7A600;
  --white: #FFFFFF;
  --black: #222222;
  --blueRp: #DDE1EE;
  --yellowRp: #FEF1D7;
  --darkGrey: #4F5361;
  --grey: #A9A9A9;
  --lightGrey: #C4C4C4;
  --transparentYellow: rgba(247, 166, 0, 0.8);
  --yellowBackground: #F8F5ED;
  --inputBorder: #C4C4C433;

  --danger: #AC0404;
  --dangerBackground: rgba(243, 215, 215, 0.93);
  --warning: #c15818;
  --warningBackground: rgba(238, 216, 203, 0.93);
  --information: #0ca183;
  --informationBackground: rgba(201, 234, 227, 0.93);
  --success: #0e6c0f;
  --successBackground: rgba(202, 236, 203, 0.93);
}

* {
  box-sizing: border-box;
}

h1 {
  font-family: 'ExpletusSans', sans-serif;
  font-weight: 400;
}

h2, h3, h4, h5, h6 {
  font-family: 'DinPro', sans-serif;
  font-weight: 400;
}

code, .code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.EditableText {
  background-color: #fff4;
}

.EditableText:hover {
  font-weight: bold;
  cursor: pointer;
}

.Clickable:hover {
  cursor: pointer;
}

.bold {
  font-weight: bold !important;
}

.hasChanged {
  font-weight: bold !important;
}

.isCritical td{
  color: var(--danger) !important;
  background-color: #AC040422;
}

.valecoMain {
  color: var(--primaryValeco);
}

.valecoSecondary {
  color: var(--secondaryValeco);
}

.bgValecoMain {
  background-color: var(--primaryValeco);
}

.bgValecoSecondary {
  background-color: var(--secondaryValeco);
}

/******************
*     POLICES     *
******************/

/* ExpletusSans */
@font-face {
  font-family: 'ExpletusSans';
  font-stretch: 100%;
  font-weight: 300;
  src: url('../font/ExpletusSans/ExpletusSans-VariableFont_wght.ttf') format('truetype-varations'),
       url('../font/ExpletusSans/ExpletusSans-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'ExpletusSansStatic';
  src: url('../font/ExpletusSans/static/ExpletusSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ExpletusSans';
  font-stretch: 100%;
  font-weight: 300;
  src: url('../font/ExpletusSans/ExpletusSans-Italic-VariableFont_wght.ttf') format('truetype-varations'),
       url('../font/ExpletusSans/ExpletusSans-Italic-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'ExpletusSansStatic';
  src: url('../font/ExpletusSans/static/ExpletusSans-Italic.ttf') format('truetype');
}

/* DinPro */
@font-face {
  font-family: 'DinPro';
  font-weight: 300;
  src: url('../font/DinPro/EnBWDINPro-Light.ttf');
}
@font-face {
  font-family: 'DinPro';
  font-weight: normal;
  src: url('../font/DinPro/EnBWDINPro-Regular.ttf');
}
@font-face {
  font-family: 'DinPro';
  font-weight: bold;
  src: url('../font/DinPro/EnBWDINPro-Bold.ttf');
}

.EnvBox {
  position: fixed;
  bottom: 0;
  left: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.EnvInfo {
  padding: 5px 10px;
  width: fit-content;
}

.EnvBox.local .EnvInfo {
  background-color: #1693e7;
  color: #05225f;
}

.EnvBox.int .EnvInfo {
  background-color: #c334e8;
  color: #5d0d66;
}

.EnvBox.uat .EnvInfo {
  background-color: #fc2626;
  color: #660d0d;
}


.Flex {
  display: flex;
}

.FlexColumn {
  flex-direction: column;
}

.Draggable {
  cursor: grab;
}

.Draggable:active {
  cursor: grabbing;
}