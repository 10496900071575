.Header {
    padding: 30px {
        bottom: 10px;
    };
    border-radius: 5px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 30px;

    .Details {
        max-width: 80%;

        h2 {
            margin: 0;
            font-family: 'ExpletusSans', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            font-size: 2.5em;
            font-weight: 100;
            font-style: normal;
            color: white;
        }

        .SpvName {
            font-size: small;
            color: var(--lightGrey);
        }

        h3 {
            margin: 10px 0;
            color: var(--secondaryValeco);
            font-size: 1.2em;
            font-weight: 700;
            font-style: normal;
        }

        h5 {
            margin: 0;
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--white);
            font-size: 1.2rem;
            font-weight: lighter;
            font-style: normal;

            .icon {
                color: var(--secondaryValeco);
                font-size: 1.2rem;
                transform: translateY(-3px);
            }
        }
    }

    .Actions {
        display: flex;
        gap: 30px;
    }

}

.Action {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    color: var(--white);
    border: 2px solid transparent;
    cursor: pointer;
    user-select: none;

    &:hover {
        border: 2px solid var(--white);
        background-color: #F4F4F433;
        border-radius: 5px;
    }
}

.CustomDateInputColor {
    color: white !important;
    &:focus {
        color: var(--primaryValeco) !important
    }
}