.ScreenBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #222222AA;
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.ModalBackground {
    min-width: 600px;
    max-width: 90vw;
    min-height: 20vh;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: var(--yellowBackground);
    border-radius: 5px;
    overflow: {
        y: auto;
        x: hidden;
    };
    @media (max-width: 600px)   {
        min-width: 100vw;
        max-width: 100vw;
        border-radius: 0;
    }
}

.Header {
    padding: 15px;
    background-color: var(--secondaryValeco);
    color: var(--white);
    > h2 {
        margin: 0;
        font-family: 'DIN', sans-serif;
        font-weight: bold;
        text-shadow: 0 0 1px var(--black);
    }
}

.ActionBox {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin: 0 10px;
    padding: 10px;
    border-top: 1px solid var(--lightGrey);
}

.Button {
    all: unset;
    background-color: var(--secondaryValeco);
    padding: 8px 20px 6px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    color: var(--white);
    font-weight: bold;
    box-shadow: 0 0 2px var(--black);

    &:hover {
        box-shadow: 0 0 4px var(--black);
        &:active {
            box-shadow: inset 0 0 2px var(--black);
        }
    }

    &:disabled {
        box-shadow: none;
        cursor: not-allowed;
        filter: opacity(50%) blur(0.75px);
    }
}

.PrimaryButton {
    background-color: var(--secondaryValeco);
    color: var(--white);
    text-shadow: 0 0 1px var(--black);
}

.SecondaryButton {
    background-color: var(--blueRp);
    color: var(--primaryValeco);
}

.ModalContent {
    display: flex;
    flex-grow: 1;
    padding: 10px;
}