.LoadingModal {
    display: flex;
    flex-direction: column;
    gap:10px;
    min-width: 33vw;
    margin: auto;
    padding: 20px 50px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 0 5px var(--black);

}

.ModalTitle {
    font-family: 'DinPro', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 1.4em;
    font-weight: bold;
}

.LoadingCentered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LoadingBox {
    border: 1px solid lightgray;
    border-radius: 1em;
    width: 100%;
    overflow: clip;
    box-shadow: inset 0 0 2px lightgray;
}

.LoadingBar {
    height: 1.5em;
    background-color: var(--primaryValeco);
    transition: 200ms;
    box-shadow: inset 0 0 2px black;
}

.LoadingText {
    position: absolute;
    font-family: 'DinPro', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 0.9em;
    color: white;
    mix-blend-mode: difference;
}

.StepText {
    font-family: 'DinPro', Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size: 0.75em;
    text-align: center;
}