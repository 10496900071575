.Nav {
    display: flex;
    background-color: var(--white);
}

.NavBody {
    width: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    gap: 20px;
    margin: 20px 0;
    user-select: none;

    > * {
        user-select: none;
    }
}

@media (max-width: 900px) {
    .Nav {
        width: 100vw;
        flex-direction: row-reverse;
    }

    .Nav.Retracted {
        width: auto;
    }

    .NavBody {
        width: auto;
        flex-grow: 1;
    }
}

.Retracted > .NavBody {
    margin: 0;
    width: 0;
}

.NavButton {
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--primaryValeco);
    color: var(--white);
    cursor: pointer;
}

.NavButtonText {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-weight: bold;
}

.NavSearch {
    padding: 0 23px 0 15px;
}

.NavItem {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    background-color: var(--white);
    overflow-y: auto;
}