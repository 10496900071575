.MainContainer {
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    cursor: pointer;
    flex-basis: 1px;

    .ContainerHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        h5 {
            color: var(--primaryValeco);
            text-align: center;
        }
    }

    &:hover {
        border-radius: 5px;
        h5 {
            color: var(--secondaryValeco);
        }
    }

    &.Selected h5 {
        color: var(--secondaryValeco);
    }
}

.FullViewMainContainer {
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: start;
    flex-grow: 1;
    cursor: pointer;
    flex-basis: 1px;

    .FullViewContainerHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        width: 100%;

        .TitleMainContainer {
            color: var(--primaryValeco);
            text-align: center;
            font-weight: bold;
            font-size: 1.5rem;
            width: 100%;
            margin: 0;
        }
    }
}

.ExpandSymbol {
    width: 100%;
    margin: -10px 0;
    text-align: center;
}


.Container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--yellowBackground);
    border-left: 4px solid var(--yellowBackground);
    border-right: 4px solid var(--yellowBackground);
    flex-grow: 1;
    &.SubContainer {
        margin-bottom: 0;
        padding: 0;
        h5 {
            color: var(--secondaryValeco);
        }
    }

    .ContainerHeader {
        display: flex;
        align-items: center;
        gap: 2px;
    }
}

.ContainerHeader h5 {
    font-size: large;
    text-align: left;
    font-weight: bold;
    color: var(--primaryValeco);
    margin: 0;
}

.SimpleTextInput {
    all: unset;
    font-family: 'DinPro', sans-serif;
    min-height: 1rem;
    width: calc(100% - 40px);

    padding: 10px 20px;
    color: var(--black);

    resize: none;
    overflow: hidden;
    word-wrap: break-word;

    &.isEditing {
        border: 2px solid var(--inputBorder);
        border-radius: 5px;
        &:focus {
            background-color: var(--white);
            border: 2px solid var(--lightGrey);
            box-shadow: inset 0 0 2px var(--lightGrey);
        }
    }
}

.TaskEnterpriseBox {
    display: flex;
    justify-content: flex-start;
    gap: 10px 30px;
    flex-wrap: wrap;
}

.TaskEnterprise {
    color: var(--primaryValeco);
    cursor: pointer;

    &:hover {
        font-weight: bold;
        color: var(--secondaryValeco);
    }
}


.TaskCategory {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.Table {
    background-color: var(--white);
    border-radius: 5px;
    padding: 20px;
    width:100%;

    table {
        border-collapse: collapse;
        margin: 10px 0;
    }

    thead {
        color: var(--grey);
    }

    th {
        text-align: left;
    }

    .DateWidth {
        width: 100px;
        padding-right: 0;
    }

    .CommentWidth {
        width: 20%;
    }

    .ActionsCol {
        width: 75px;
        padding-right: 0;
        color: var(--lightGrey) !important;

        > * {
            transform: translateY(-3px);
            &:hover {
                color: var(--primaryValeco);
            }
        }
    }

    td {
        border-top: 1px solid var(--grey);
        color: var(--black);
        vertical-align: top;
        padding: 5px 20px 30px 0;
    }
}

.TaskFormHeader {
    background-color: var(--secondaryValeco);
    color: var(--white);
    font-weight: bold;
    padding: 20px 20px;

    h2 {
        margin: 0;
    }
}

.TaskFormBody {
    padding: 10px 20px;
}

.TaskFormButton {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    padding: 10px 20px;

    .CancelBtn {
        background-color: var(--blueRp);
        padding: 3px 10px;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;

        &:hover {
            box-shadow: 0 0 2px var(--grey);
        }
    }

    .ContinueBtn {
        background-color: var(--secondaryValeco);
        padding: 3px 10px;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;

        &:hover {
            box-shadow: 0 0 2px var(--grey);
        }
    }
}

.EnterpriseCardBox {
    position: absolute;
    transform: translateX(54px);
    border: 1px solid var(--primaryValeco);
    max-height: 30vh;
    overflow-y: scroll;
    flex-grow: 1;
}

.EnterpriseCard {
    padding: 5px 10px;
    border-bottom: 1px solid var(--lightGrey);
    background-color: var(--white);
    cursor: pointer;
    &:hover {
        font-weight: bold;
        color: var(--secondaryValeco);
    }
}

.PlanningDateSelectionBox {
    padding: 10px 10px 0;
    display: flex;
    gap: 20px;
    color: var(--black);
    & > :last-child {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex-grow: 1;
    }
}

.RichTextSelector {
    padding: 0 10px 10px;
    display: flex;
    align-items: flex-end;
    gap: 3px;
    font-size: 0.9rem;
}