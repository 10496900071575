.RichText {
    text-align: left;
    width: 100%;
}

.EditableText {
    padding: 15.5px 13px;
    line-height: 1.4375em;

    &.isEditing {
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        background-color: var(--white);
        &.Error {
            border: 1px solid #d32f2f;
        }
        &:focus {
            border: 2px solid var(--secondaryValeco);
            padding: 14.5px 12px;
            box-shadow: inset 0 0 2px var(--lightGrey);
        }
    }
}

.EditableText * {
    margin: 0;
}

.TagContextAction {
    position: absolute;
    background-color: #f1f1f1;
    border-radius: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    z-index: 1300;
}