.button {
    display: flex;
    align-items: center;

    width: auto;
    height: 22px;

    padding: 2px 6px;

    border: none;
    background-color: var(--primaryValeco);
    color: var(--white);
    border-radius: 12px;
    cursor: pointer;
}
.button:hover {
    box-shadow: 0 0 3px 0 black;
}
.button:active {
    background-color: rgba(43, 75, 180, 0.356)
}

button > p {
    width: 100%;
    margin: 0;
    padding: 0;

    font-family: 'DinPro', sans-serif;
    font-weight: bold;
    font-size: 10px;
    color: var(--secondaryValeco);
}

button > span {
    margin-left: 3px;
}

.addButtonBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    width: fit-content;
    height: 45px;

    cursor: pointer;
    font-size: 14px;
}
