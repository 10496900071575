.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 60px;

    padding: 0 25px;

    background-color: var(--primaryValeco);
    color: var(--white)
}

.headerLogo {
    height: 70%;
}
.headerLogo > img {
    height: 100%;
}

.headerTitle {
    font-family: 'DinPro', sans-serif;
    font-variant: all-petite-caps;
    font-size: 2em;
}

.headerAccount {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 10px;
    font-size: 1em;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 5px 0;
}

.headerAccount:hover {
    border: 2px solid var(--inputBorder);
    background-color: #2222;
}

.headerAccount:hover .LogoutText {
    transition: 2s;
    max-height: 500px;
}

.headerAccount > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.headerAccount .LogoutText{
    transition: 2s;
    color: var(--lightGrey);
    text-decoration: none;
    font-size: 0.8em;
    max-height: 0;
    overflow: hidden;
}

.headerAccount .Icon {
    color: var(--secondaryValeco);
    padding-left: 5px;
    font-size: 2em;
}